import React from 'react';
import { Route } from 'react-router-dom';
import { PrivateLayoutContainer } from 'components/application/layouts/private-layout';
import { ClaimsPortal } from 'api/claims/claimTypes';
import { useAuthState } from 'components/authentication/context/authContext';
import { userProfileSelector } from 'components/authentication/context/authSelectors';
import { RbacAdminRolesKeys } from 'constants/rbac';
import PublicRoutes from './PublicRoutes';
import { privateRoutes } from './routesConstants';
import PermittedRoute from './PermittedRoute';
import SettingsRoutes from './SettingsRoutes';

const ClaimsListWrapper = React.lazy(() => import('components/Claims/ClaimsList/ClaimsListContainer'));
const SubmissionFormContainerWrapper = React.lazy(() => (
  import('components/Claims/SubmissionForm/SubmissionFormContainer')
));
const ClaimsSubmissionsListWrapper = React.lazy(() => (
  import('components/Claims/ClaimsSubmissionsList/ClaimsSubmissionsListContainer')
));
const ClaimDetails = React.lazy(() => import('components/Claims/ClaimDetails'));

const BranchesContainerWrapper = React.lazy(() => (
  import('components/Settings/BranchManagement/Branches/BranchesContainer')
));

const UserManagement = React.lazy(() => import('components/Settings/UserManagement/UserManagement'));
const AddUser = React.lazy(() => import('components/Settings/UserManagement/AddUser/AddUserContainer'));
const EditUser = React.lazy(() => import('components/Settings/UserManagement/EditUser/EditUserContainer'));
const Users = React.lazy(() => import('components/Settings/UserManagement/Users/UsersContainer'));

const BranchManagement = React.lazy(() => import('components/Settings/BranchManagement/BranchManagement'));
const BranchAddContainer = React.lazy(() => (
  import('components/Settings/BranchManagement/BranchAdd/BranchAddContainer')
));
const BranchEditContainer = React.lazy(() => (
  import('components/Settings/BranchManagement/BranchEdit/BranchEditContainer')
));

const Reports = React.lazy(() => import('components/Reports/Reports'));

const Dashboard = React.lazy(() => import('components/Dashboard/containers/DashboardPage/DashboardPage'));
const AgingDashboard = React.lazy(() => import('components/Dashboard/containers/AgeingDashboard/AgingDashboard'));
const CliniciansDashboard = React.lazy(() => (
  import('components/Dashboard/containers/CliniciansDashboard/CliniciansDashboard')
));
const OverviewDashboardContainer = React.lazy(() => (
  import('components/Dashboard/containers/OverviewDashboard/OverviewDashboardContainer')
));
const PaymentsContainer = React.lazy(() => import('components/Dashboard/containers/Payments/PaymentsContainer'));
const CollectionsDashboard = React.lazy(() => (
  import('../../Dashboard/containers/CollectionsDashboard/CollectionsDashboard')
));
const RejectionsDashboard = React.lazy(() => (
  import('../../Dashboard/containers/RejectionsDashboard/RejectionsDashboard')
));

const Claims = React.lazy(() => import('../../Claims/Claims'));
const NotFound = React.lazy(() => import('../../Route/NotFound'));

export const PrivateRoutes: React.FC = () => {
  const authState = useAuthState();
  const userProfile = userProfileSelector(authState);
  const isAdmin = !!userProfile?.rbac_role_id && RbacAdminRolesKeys.includes(userProfile?.rbac_role_id);

  return (
    <>
      <Route element={<PrivateLayoutContainer showMenu isMobileAdaptive={false} />}>
        <Route
          path={privateRoutes.home}
          element={<Dashboard />}
        >
          <Route
            path=""
            element={<OverviewDashboardContainer />}
          />
        </Route>
        <Route
          path={privateRoutes.dashboard}
          element={<Dashboard />}
        >
          <Route
            path={privateRoutes.dashboardOverview}
            element={<OverviewDashboardContainer />}
          />
          <Route
            path={privateRoutes.dashboardCollections}
            element={<CollectionsDashboard />}
          />
          <Route
            path={privateRoutes.dashboardPayments}
            element={<PaymentsContainer />}
          />
          <Route
            path={privateRoutes.dashboardClinicians}
            element={<CliniciansDashboard />}
          />
          <Route
            path={privateRoutes.dashboardAgeing}
            element={<AgingDashboard />}
          />
          <Route
            path={privateRoutes.dashboardRejections}
            element={<RejectionsDashboard />}
          />
        </Route>
        <Route path={privateRoutes.claims} element={<Claims />}>
          <Route
            path={privateRoutes.claimCreateSubmissionForm}
            element={<SubmissionFormContainerWrapper key={privateRoutes.claimCreateSubmissionForm} />}
          />
          <Route
            path={privateRoutes.claimSubmissionForm}
            element={<SubmissionFormContainerWrapper key={privateRoutes.claimSubmissionForm} />}
          />
          <Route
            path={privateRoutes.claimCreateDraftResubmissionForm}
            element={(
              <SubmissionFormContainerWrapper
                isResubmissionCreation
                key={privateRoutes.claimCreateDraftResubmissionForm}
              />
            )}
          />
          <Route
            path={privateRoutes.claimSubmissions}
            element={<ClaimsSubmissionsListWrapper />}
          />
          <Route path={privateRoutes.claimDetails} element={<ClaimDetails />} />
          <Route
            path={privateRoutes.claimEClaimLink}
            element={<ClaimsListWrapper key={ClaimsPortal.eClaimLink} portal={ClaimsPortal.eClaimLink} />}
          />
          <Route
            path={privateRoutes.claimRiayati}
            element={<ClaimsListWrapper key={ClaimsPortal.Riayati} portal={ClaimsPortal.Riayati} />}
          />
          <Route
            path={privateRoutes.claimShafafiya}
            element={<ClaimsListWrapper key={ClaimsPortal.Shafafiya} portal={ClaimsPortal.Shafafiya} />}
          />
        </Route>
        <Route path={privateRoutes.report} element={<Reports />} />
        {SettingsRoutes({})}
        <Route element={<PermittedRoute isPermitted={isAdmin} />}>
          <Route
            path={privateRoutes.branchList}
            element={<BranchManagement />}
          >
            <Route
              path={privateRoutes.branchAddForm}
              element={<BranchAddContainer />}
            />
            <Route
              path={privateRoutes.branchForm}
              element={<BranchEditContainer />}
            />
            <Route path={privateRoutes.branchList} element={<BranchesContainerWrapper />} />
          </Route>
          <Route
            path={privateRoutes.userList}
            element={<UserManagement />}
          >
            <Route
              path={privateRoutes.userAddForm}
              element={<AddUser />}
            />
            <Route
              path={privateRoutes.userForm}
              element={<EditUser />}
            />
            <Route path={privateRoutes.userList} element={<Users />} />
          </Route>
        </Route>
        <Route
          path="*"
          element={<NotFound />}
        />
      </Route>
      {PublicRoutes({})}
    </>
  );
};

export default PrivateRoutes;
