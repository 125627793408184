import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { Spin } from 'custom-test-antd';
import { PrivateLayoutContainer } from 'components/application/layouts/private-layout';
import SettingsRoutes from 'components/application/routes/SettingsRoutes';
import { OnboardStep } from './context/userOnboardingReducer';
import { LoadingLayout } from '../application/layouts/loading-layout';
import { privateRoutes } from '../application/routes/routesConstants';
import { UserOnboardingLayout } from './layout';

const OnboardFacilityPage = React.lazy(() => (
  import('./onboard-facility/OnboardFacilityPage')
));

const CallSchedulerContainer = React.lazy(() => (
  import('./call-scheduler/CallSchedulerContainer')
));

interface Props {
  onboardStep?: OnboardStep;
  children?: React.ReactNode;
}

export const UserOnboarding = ({ children, onboardStep }: Props) => {
  if (onboardStep === OnboardStep.Loading) {
    return (
      <Routes>
        <Route
          element={<PrivateLayoutContainer showMenu={false} isMobileAdaptive />}
        >
          <Route
            path="*"
            element={(
              <LoadingLayout>
                <Spin spinning tip="Checking user..." />
              </LoadingLayout>
            )}
          />
        </Route>
      </Routes>
    );
  }
  if (onboardStep === OnboardStep.EnterBranch) {
    return (
      <Routes>
        <Route
          element={<PrivateLayoutContainer showMenu={false} isMobileAdaptive />}
        >
          <Route element={<UserOnboardingLayout />}>
            <Route
              path={privateRoutes.home}
              element={<OnboardFacilityPage />}
            />
            <Route
              path={privateRoutes.signUpOnboardFacility}
              element={<OnboardFacilityPage />}
            />
            <Route
              path="*"
              element={<OnboardFacilityPage />}
            />
          </Route>
          {SettingsRoutes({ showBackBtn: true })}
        </Route>
      </Routes>
    );
  }
  if (onboardStep === OnboardStep.ScheduleCall) {
    return (
      <Routes>
        <Route
          element={<PrivateLayoutContainer showMenu={false} isMobileAdaptive />}
        >
          <Route element={<UserOnboardingLayout />}>
            <Route
              path={privateRoutes.home}
              element={<CallSchedulerContainer />}
            />
            <Route
              path={privateRoutes.signUpOnboardFacility}
              element={<OnboardFacilityPage />}
            />
            <Route
              path={privateRoutes.signUpScheduleCall}
              element={<CallSchedulerContainer />}
            />
          </Route>
          <Route
            path="*"
            element={<CallSchedulerContainer />}
          />
          {SettingsRoutes({ showBackBtn: true })}
        </Route>
      </Routes>
    );
  }
  return (
    <Routes>
      {children}
    </Routes>
  );
};

export default UserOnboarding;
