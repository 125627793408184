import { useRef, useEffect } from 'react';

/**
 * AbortController which aborts automatically
 * 1) once dependencies are changed
 * 2) once component gets unmounted
 * @param  {any[]} deps
 */
export const useAbortControllerGetter = (deps: any[]) => {
  const abortControllerRef = useRef<AbortController | undefined>(undefined);
  const getAbortController = () => abortControllerRef.current;

  useEffect(() => {
    abortControllerRef.current?.abort();
    abortControllerRef.current = new AbortController();

    return () => abortControllerRef.current?.abort();
  }, deps);

  return getAbortController;
};
