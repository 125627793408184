import { ApiError, commonFetch, commonNonAuthFetch } from '../apiHelper';
import {
  CreatedIntention,
  CreateRegistrationIntentionData,
  FinishBranchCreationStep,
  IntentionCheck,
  MeResult,
} from './authenticationTypes';

export class AuthenticationApi {
  static signIn = async (email: string, password: string, rememberMe: boolean) => {
    const requestURL = `${process.env.REACT_APP_BACKEND_URL}/v3/sign-in`;
    const backendResponse = await commonNonAuthFetch(requestURL, {
      method: 'POST',
      body: JSON.stringify({ email, password, rememberMe }),
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (backendResponse.ok) {
      return backendResponse.json();
    }
    throw new ApiError(backendResponse);
  };

  static signOut = async () => {
    const requestURL = `${process.env.REACT_APP_BACKEND_URL}/v3/sign-out`;
    const backendResponse = await commonNonAuthFetch(requestURL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (backendResponse.ok) {
      return backendResponse.json();
    }
    throw new ApiError(backendResponse);
  };

  static me = async (showErrors = true): Promise<MeResult> => {
    const requestURL = `${process.env.REACT_APP_BACKEND_URL}/v3/me`;
    const backendResponse = await commonFetch(requestURL, {
      method: 'GET',
    }, showErrors);
    if (backendResponse.ok) {
      return backendResponse.json();
    }
    throw new ApiError(backendResponse);
  };

  static forgotPassword = async (email: string) => {
    const backendResponse = await commonFetch(`${process.env.REACT_APP_BACKEND_URL}/auth/forgot-password`, {
      method: 'POST',
      body: JSON.stringify({ email, service: 'portal-uae' }),
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (backendResponse.ok) {
      return backendResponse.json();
    }
    throw new ApiError(backendResponse);
  };

  static changePassword = async (password: string, token: string) => {
    const backendResponse = await commonFetch(`${process.env.REACT_APP_BACKEND_URL}/auth/change-password/${token}`, {
      method: 'POST',
      body: JSON.stringify({ password }),
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (backendResponse.ok) {
      return backendResponse.json();
    }
    throw new ApiError(backendResponse);
  };

  static createRegistrationIntention = async (values: CreateRegistrationIntentionData): Promise<CreatedIntention> => {
    const backendResponse = await commonFetch(`${process.env.REACT_APP_BACKEND_URL}/v3/sign-up/intention`, {
      method: 'POST',
      body: JSON.stringify({ ...values, service: 'portal-uae' }),
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (backendResponse.ok) {
      return backendResponse.json();
    }
    throw new ApiError(backendResponse);
  };

  static checkRegistrationIntention = async (intentionId: string): Promise<IntentionCheck> => {
    const backendResponse = await commonFetch(`${process.env.REACT_APP_BACKEND_URL}/v3/sign-up/check`, {
      method: 'POST',
      body: JSON.stringify({ intentionId }),
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (backendResponse.ok) {
      return backendResponse.json();
    }
    throw new ApiError(backendResponse);
  };

  static resendRegistrationIntention = async (intentionId: string) => {
    const backendResponse = await commonFetch(`${process.env.REACT_APP_BACKEND_URL}/v3/sign-up/resend`, {
      method: 'POST',
      body: JSON.stringify({ intentionId }),
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (backendResponse.ok) {
      return backendResponse.json();
    }
    throw new ApiError(backendResponse);
  };

  static executeRegistrationIntention = async (verificationId: string): Promise<IntentionCheck> => {
    const backendResponse = await commonFetch(`${process.env.REACT_APP_BACKEND_URL}/v3/sign-up/execute`, {
      method: 'POST',
      body: JSON.stringify({ verificationId }),
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (backendResponse.ok) {
      return backendResponse.json();
    }
    throw new ApiError(backendResponse);
  };

  static checkInvitation = async (invitationId: string): Promise<any> => {
    const backendResponse = await commonFetch(
      `${process.env.REACT_APP_BACKEND_URL}/auth/verify-invitation/${invitationId}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );
    if (backendResponse.ok) {
      return backendResponse.json();
    }
    throw new ApiError(backendResponse);
  };

  static acceptInvitation = async (invitationId: string, values: any): Promise<any> => {
    const backendResponse = await commonFetch(
      `${process.env.REACT_APP_BACKEND_URL}/auth/update-invitation/${invitationId}`,
      {
        method: 'POST',
        body: JSON.stringify(values),
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );

    if (backendResponse.ok) {
      return backendResponse.json();
    }
    throw new ApiError(backendResponse);
  };

  static loginAsUser = async (userId: number | null): Promise<void> => {
    const requestURL = `${process.env.REACT_APP_BACKEND_URL}/v3/represent-as/${userId}`;
    const backendResponse = await commonFetch(requestURL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (backendResponse.ok) {
      return backendResponse.json();
    }
    throw new ApiError(backendResponse);
  };
}

export default AuthenticationApi;
