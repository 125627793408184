import { FC } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { privateRoutes } from './routesConstants';

type Props = {
  isPermitted: boolean;
  redirectPath?: string;
  children?: React.ReactNode
};

export const PermittedRoute: FC<Props> = ({
  isPermitted,
  redirectPath = privateRoutes.home,
  children,
}: Props) => {
  if (!isPermitted) {
    return <Navigate to={redirectPath} replace />;
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children || <Outlet />}</>;
};

export default PermittedRoute;
