import { ApiError, commonFetch } from '../apiHelper';

export class OrganizationApi {
  static getOrganizationUsers = async () => {
    const requestURL = `${process.env.REACT_APP_BACKEND_URL}/organization/users?limit=100&offset=0`;
    const backendResponse = await commonFetch(requestURL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (backendResponse.ok) {
      return backendResponse.json();
    }
    throw new ApiError(backendResponse);
  };

  static getOrganizationBranches = async (organizationId: string) => {
    const requestURL = `${process.env.REACT_APP_BACKEND_URL}/v3/organizations/${organizationId}/branches`;
    const backendResponse = await commonFetch(requestURL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (backendResponse.ok) {
      return backendResponse.json();
    }
    throw new ApiError(backendResponse);
  };

  static getOrganizationBranchesCount = async (organizationId: string) => {
    const requestURL = `${process.env.REACT_APP_BACKEND_URL}/v3/organizations/${organizationId}/branches/count`;
    const backendResponse = await commonFetch(requestURL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (backendResponse.ok) {
      return backendResponse.json();
    }
    throw new ApiError(backendResponse);
  };

  static postRequestPortalUpgrade = async () => {
    const requestURL = `${process.env.REACT_APP_BACKEND_URL}/v3/organizations/request-portal-upgrade`;
    const backendResponse = await commonFetch(requestURL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (backendResponse.ok) {
      return;
    }
    throw new ApiError(backendResponse);
  };

  // TODO: change backend url appropriately to complete-schedule-call
  static postCompleteScheduleCallStep = async () => {
    const requestURL = `${process.env.REACT_APP_BACKEND_URL}/v3/organizations/current/skip-sales-call`;
    const backendResponse = await commonFetch(requestURL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (backendResponse.ok) {
      return;
    }
    throw new ApiError(backendResponse);
  };
}

export default OrganizationApi;
