import React from 'react';
import {
  Route,
} from 'react-router-dom';

import { publicRoutes } from 'components/application/routes/routesConstants';
import { AuthLayout } from '../layouts/auth-layout';

const SignInContainer = React.lazy(() => import('../../authentication/sign-in/SignInContainer'));
const SignUpContainer = React.lazy(() => import('../../authentication/sign-up/SignUpContainer'));
const ForgotPasswordContainer = React.lazy(() => (
  import('../../authentication/forgot-password/ForgotPasswordContainer')
));
const EmailSentContainer = React.lazy(() => import('../../authentication/email-sent/EmailSentContainer'));
const ConfirmEmailSentContainer = React.lazy(() => (
  import('../../authentication/confirm-email-sent/ConfirmEmailSentContainer')
));
const VerifyIntention = React.lazy(() => (
  import('../../authentication/verify-intention/VerifyIntention')
));
const PasswordUpdated = React.lazy(() => import('../../authentication/password-updated/PasswordUpdated'));
const RegistrationCompletedContainer = React.lazy(() => (
  import('../../authentication/registration-completed/RegistrationCompletedContainer')
));
const UpdatePasswordContainer = React.lazy(() => (
  import('../../authentication/update-password/UpdatePasswordContainer')
));
const InvitationContainer = React.lazy(() => (
  import('../../authentication/invitation/InvitationContainer')
));

export const PublicRoutes: React.FC = () => (
  <Route
    element={<AuthLayout />}
  >
    <Route
      path={publicRoutes.signIn}
      element={<SignInContainer />}
    />
    <Route
      path={publicRoutes.signUp}
      element={<SignUpContainer />}
    />
    <Route
      path={publicRoutes.forgotPassword}
      element={<ForgotPasswordContainer />}
    />
    <Route
      path={publicRoutes.updatePassword}
      element={<UpdatePasswordContainer />}
    />
    <Route
      path={publicRoutes.emailSent}
      element={<EmailSentContainer />}
    />
    <Route
      path={publicRoutes.passwordUpdated}
      element={<PasswordUpdated />}
    />
    <Route
      path={publicRoutes.registrationCompleted}
      element={<RegistrationCompletedContainer />}
    />
    <Route
      path={publicRoutes.confirmEmailSent}
      element={<ConfirmEmailSentContainer />}
    />
    <Route
      path={publicRoutes.verifyIntention}
      element={<VerifyIntention />}
    />
    <Route
      path={publicRoutes.acceptInvitation}
      element={<InvitationContainer />}
    />
    <Route
      path="*"
      element={<SignInContainer />}
    />
  </Route>
);

export default PublicRoutes;
