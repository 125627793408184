/* eslint-disable class-methods-use-this */
import { RbacRuleFactory } from 'services/rbac-manager/interfaces';

export class RbacLightRuleFactory implements RbacRuleFactory {
  createRule(_rule: string) {
    return {
      execute: (_payload_: any) => true, // Keeps light check without rules
    };
  }
}
