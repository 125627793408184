import { ApiError, commonFetch, encodeQueryFilter } from '../apiHelper';

import { DiagnosesCodes, BenefitPackage, Clinician, ActivityCode, EncounterType, Payer } from './referentialDataTypes';

export class ReferentialDataApi {
  static getDiagnoses = async (search: string, authority: string): Promise<{
    data: DiagnosesCodes[],
    count: number
  }> => {
    const filter = {
      search,
      authorityCode: authority,
    };
    const requestURL = `${process.env.REACT_APP_BACKEND_URL}/diagnoses${encodeQueryFilter(filter)}`;
    const backendResponse = await commonFetch(requestURL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (backendResponse.ok) {
      return backendResponse.json();
    }
    throw new ApiError(backendResponse);
  };

  static getBenefitPackageList = async (payerId: string, search?: string): Promise<BenefitPackage[]> => {
    const requestFilter: Record<string, any> = {
      payerId,
    };
    if (search) {
      requestFilter.search = search;
    }
    const requestURL = `${process.env.REACT_APP_BACKEND_URL}/benefit-package${encodeQueryFilter(requestFilter)}`;
    const backendResponse = await commonFetch(requestURL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (backendResponse.ok) {
      return backendResponse.json();
    }
    throw new ApiError(backendResponse);
  };

  static getClinicians = async (clinicianId: string, authority: string): Promise<{
    data: Clinician[],
    count: number,
  }> => {
    const filter = {
      clinician: clinicianId,
      name: clinicianId,
      authorityCode: authority,
    };
    const requestURL = `${process.env.REACT_APP_BACKEND_URL}/clinician${encodeQueryFilter(filter)}`;
    const backendResponse = await commonFetch(requestURL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (backendResponse.ok) {
      return backendResponse.json();
    }
    throw new ApiError(backendResponse);
  };

  static getActivityCode = async (searchString: string, activityType: string, authority: string): Promise<{
    data: ActivityCode[],
    count: number,
  }> => {
    const filter = {
      search: searchString,
      activityType,
      authorityCode: authority,
    };
    const requestURL = `${process.env.REACT_APP_BACKEND_URL}/activities${encodeQueryFilter(filter)}`;
    const backendResponse = await commonFetch(requestURL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (backendResponse.ok) {
      return backendResponse.json();
    }
    throw new ApiError(backendResponse);
  };

  static getEncounters = async (authorityCode: string): Promise<{
    data: EncounterType[],
    count: number,
  }> => {
    const filter = {
      authorityCode,
    };
    const requestURL = `${process.env.REACT_APP_BACKEND_URL}/encounter${encodeQueryFilter(filter)}`;
    const backendResponse = await commonFetch(requestURL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (backendResponse.ok) {
      return backendResponse.json();
    }
    throw new ApiError(backendResponse);
  };

  static getAllPayersReceivers = async (query: {
    authorityCode?: string;
    limit?: number;
  }): Promise<{
    data: Payer[],
    count: number,
  }> => {
    const filter: Record<string, any> = {};
    if (query.authorityCode) {
      filter.authorityCode = query.authorityCode;
    }
    if (query.limit) {
      filter.limit = query.limit;
    }
    const requestURL = `${process.env.REACT_APP_BACKEND_URL}/payers${encodeQueryFilter(filter)}`;
    const backendResponse = await commonFetch(requestURL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (backendResponse.ok) {
      return backendResponse.json();
    }
    throw new ApiError(backendResponse);
  };
}

export default ReferentialDataApi;
