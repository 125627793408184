import { ApiError, commonFetch, encodeQueryFilter } from '../apiHelper';
import { BranchData, BranchListResult, Branch, BranchApiDto, BranchCredentialsValidationToken } from './branchTypes';

export class BranchApi {
  static getUserBranches = async (): Promise<BranchData[]> => {
    const requestURL = `${process.env.REACT_APP_BACKEND_URL}/users/current/branches`;
    const backendResponse = await commonFetch(requestURL, {
      method: 'GET',
    });
    if (backendResponse.ok) {
      return backendResponse.json();
    }
    throw new ApiError(backendResponse);
  };

  static checkBranch = async (branch: BranchApiDto): Promise<BranchCredentialsValidationToken> => {
    const requestURL = `${process.env.REACT_APP_BACKEND_URL}/v3/branches/check`;
    const backendResponse = await commonFetch(requestURL, {
      method: 'POST',
      body: JSON.stringify(branch),
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (backendResponse.ok) {
      return backendResponse.json();
    }
    throw new ApiError(backendResponse);
  };

  static createBranchesFromTokens = async (tokens: string[]): Promise<void> => {
    const requestURL = `${process.env.REACT_APP_BACKEND_URL}/v3/branches/upsert/from-tokens`;
    const backendResponse = await commonFetch(requestURL, {
      method: 'POST',
      body: JSON.stringify({ validationTokens: tokens }),
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (backendResponse.ok) {
      return backendResponse.json();
    }
    throw new ApiError(backendResponse);
  };

  static getBranches = async (
    filter: { search?: string },
    pagination: { pageSize: number; current: number },
  ): Promise<BranchListResult> => {
    const queryParams: any = {
      limit: pagination.pageSize,
      skip: (pagination.current - 1) * pagination.pageSize,
      search: filter.search ?? '',
    };

    const requestURL = `${process.env.REACT_APP_BACKEND_URL}/v3/branches${encodeQueryFilter(queryParams)}`;
    const backendResponse = await commonFetch(requestURL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (backendResponse.ok) {
      return backendResponse.json();
    }
    throw new ApiError(backendResponse);
  };

  static getBranch = async (branchId: string): Promise<Branch> => {
    const requestURL = `${process.env.REACT_APP_BACKEND_URL}/v3/branches/${branchId}`;
    const backendResponse = await commonFetch(requestURL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (backendResponse.ok) {
      return backendResponse.json();
    }
    throw new ApiError(backendResponse);
  };

  static getAllBranch = async (): Promise<Omit<Branch, 'login' | 'password'>[]> => {
    const requestURL = `${process.env.REACT_APP_BACKEND_URL}/v3/branches/all`;
    const backendResponse = await commonFetch(requestURL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (backendResponse.ok) {
      return backendResponse.json();
    }
    throw new ApiError(backendResponse);
  };

  static updateBranch = async (branch: Pick<Branch, 'login' | 'password' | 'name' | 'id'>): Promise<Branch> => {
    const requestURL = `${process.env.REACT_APP_BACKEND_URL}/v3/branches/${branch.id}`;
    const backendResponse = await commonFetch(requestURL, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ ...branch, id: undefined }),
    });
    if (backendResponse.ok) {
      return backendResponse.json();
    }
    throw new ApiError(backendResponse);
  };

  static deleteBranch = async (branchId: string): Promise<void> => {
    const requestURL = `${process.env.REACT_APP_BACKEND_URL}/v3/branches/${branchId}`;
    const backendResponse = await commonFetch(requestURL, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (backendResponse.ok) {
      return;
    }
    throw new ApiError(backendResponse);
  };
}
