export class Service {
  static readonly componentNotification: unique symbol = Symbol('componentNotificationService');

  static readonly localStorage: unique symbol = Symbol('localStorageService');

  static readonly webAnalyticsConfig: unique symbol = Symbol('webAnalyticsConfig');

  static readonly webAnalytics: unique symbol = Symbol('webAnalyticsService');
}

export default Service;
