import React from 'react';

import { Outlet } from 'react-router-dom';
import { isMobile } from '../../../utils/mobileDetector';

import './UserOnboardingLayout.pcss';

type Props = {
  children?: React.ReactNode
};

export const UserOnboardingLayout: React.FC<Props> = ({ children }) => (
  <div className={`UserOnboardingLayout ${isMobile() ? 'mobile' : ''}`}>
    <Outlet />
  </div>
);

export default UserOnboardingLayout;
