import React, { useState } from 'react';
import produce from 'immer';
import { Popover } from 'custom-test-antd';
import { useLocation, useNavigate } from 'react-router-dom';

import { ReactComponent as AppMenu } from 'assets/app-menu.svg';
import { RbacAdminRolesKeys } from 'constants/rbac';

import dashboardMenuIcon from 'assets/dashboard_menu_icon.svg';
import claimsMenuIcon from 'assets/claims_menu_icon.svg';
import reportMenuIcon from 'assets/report_menu_icon.svg';

import { Menu, MenuLabel, MenuTextLabel } from './menu';
import { privateRoutes } from '../../../routes/routesConstants';
import { useAuthState } from '../../../../authentication/context/authContext';
import { userProfileSelector } from '../../../../authentication/context/authSelectors';

import './MenuButton.pcss';

export enum MenuKey {
  Dashboard = 'dashboard',
  Reports = 'reports',
  Claims = 'claimSubmissions',
  BranchManagement = 'branchManagement',
  UserManagement = 'userManagement',
}

const MenuRoutes: { [key: string]: string } = {
  [MenuKey.Dashboard]: privateRoutes.dashboardOverview,
  [MenuKey.Reports]: privateRoutes.report,
  [MenuKey.Claims]: privateRoutes.claimSubmissions,
  [MenuKey.BranchManagement]: privateRoutes.branchList,
  [MenuKey.UserManagement]: privateRoutes.userList,
};

const prepareItems = (items: any[]) => (
  produce(items, (draft) => draft
    .filter((item) => item?.visible !== false))
    .map((item) => ({ ...item, visible: undefined }))
);

interface Props {
  className?: string;
}

export const MenuButton: React.FC<Props> = ({ className }) => {
  const [visible, setVisible] = useState(false);
  const state = useAuthState();
  const userProfile = userProfileSelector(state);

  const moduleItems = [
    {
      label: <MenuLabel label="Dashboard" description="" src={dashboardMenuIcon} />,
      key: MenuKey.Dashboard,
    },
    {
      label: <MenuLabel label="Reports" description="" src={reportMenuIcon} />,
      key: MenuKey.Reports,
    },
    {
      label: <MenuLabel label="Claims" description="" src={claimsMenuIcon} />,
      key: MenuKey.Claims,
    },
  ];

  const toolItems = [
    {
      label: <MenuTextLabel label="Branch Management" />,
      key: MenuKey.BranchManagement,
      visible: RbacAdminRolesKeys.includes(userProfile!.rbac_role_id),
    },
    {
      label: <MenuTextLabel label="User Management" />,
      key: MenuKey.UserManagement,
      visible: RbacAdminRolesKeys.includes(userProfile!.rbac_role_id),
    },
  ];

  const preparedToolItems = prepareItems(toolItems);

  const hide = () => {
    setVisible(false);
  };

  const handleVisibleChange = (newVisible: boolean) => {
    setVisible(newVisible);
  };

  const navigate = useNavigate();
  const location = useLocation();
  let selectedKey: string = MenuKey.Dashboard;
  if (location.pathname !== privateRoutes.home) {
    const foundKey = Object.entries(MenuRoutes).find(([, value]) => {
      const regExp = new RegExp(`^${value}`);
      return regExp.test(location.pathname);
    })?.[0];
    if (foundKey) {
      selectedKey = foundKey;
    }
  }

  return (
    <Popover
      className="MenuButton"
      trigger="click"
      open={visible}
      onOpenChange={handleVisibleChange}
      placement="bottomLeft"
      overlayClassName="MenuButton-overlay"
      content={(
        <div>
          <div className="MenuButton-header">Modules</div>
          <Menu
            onClick={(e) => {
              navigate(MenuRoutes[e.key]);
              hide();
            }}
            selectedKey={selectedKey}
            items={prepareItems(moduleItems)}
            className="MenuButton-modulesMenu"
          />
          {
            preparedToolItems.length ? (
              <>
                <div className="MenuButton-header">Tools</div>
                <Menu
                  onClick={(e) => {
                    navigate(MenuRoutes[e.key]);
                    hide();
                  }}
                  selectedKey={selectedKey}
                  items={preparedToolItems}
                  className="MenuButton-toolMenu"
                />
              </>
            ) : ''
          }

        </div>
      )}
    >
      <AppMenu className={`${className ?? ''}`} />
    </Popover>
  );
};

export default MenuButton;
