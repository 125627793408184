import { ApiError, commonFetch, encodeQueryFilter } from '../apiHelper';
import { Submission, SubmissionListFilter, SubmissionListResult, TransactionPreview } from './submissionsTypes';

export class SubmissionsApi {
  static listSubmissions = async (filter: SubmissionListFilter, pagination: {
    pageSize: number;
    current: number
  }): Promise<SubmissionListResult> => {
    let requestFilter: any = {
      limit: pagination.pageSize,
      skip: (pagination.current - 1) * pagination.pageSize,
    };
    requestFilter = { ...requestFilter, ...filter };
    const requestURL = `${process.env.REACT_APP_BACKEND_URL}/submissions${encodeQueryFilter(requestFilter)}`;
    const backendResponse = await commonFetch(requestURL, {
      method: 'GET',
    });
    if (backendResponse.ok) {
      return backendResponse.json();
    }
    throw new ApiError(backendResponse);
  };

  static createSubmission = async (submission: any): Promise<Submission> => {
    const backendResponse = await commonFetch(`${process.env.REACT_APP_BACKEND_URL}/submissions`, {
      method: 'POST',
      body: JSON.stringify(submission),
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (backendResponse.ok) {
      return backendResponse.json();
    }
    throw new ApiError(backendResponse);
  };

  static updateSubmission = async (id: string, submission: any): Promise<Submission> => {
    const backendResponse = await commonFetch(`${process.env.REACT_APP_BACKEND_URL}/submissions/${id}`, {
      method: 'PATCH',
      body: JSON.stringify(submission),
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (backendResponse.ok) {
      return backendResponse.json();
    }
    throw new ApiError(backendResponse);
  };

  static previewTestTransaction = async (submissionId: string): Promise<TransactionPreview> => {
    const backendResponse = await commonFetch(
      `${process.env.REACT_APP_BACKEND_URL}/submissions/${submissionId}/preview/test`,
      { method: 'GET' },
    );
    if (backendResponse.ok) {
      return backendResponse.json();
    }
    throw new ApiError(backendResponse);
  };

  static previewProductionTransaction = async (submissionId: string): Promise<TransactionPreview> => {
    const backendResponse = await commonFetch(
      `${process.env.REACT_APP_BACKEND_URL}/submissions/${submissionId}/preview/production`,
      { method: 'GET' },
    );
    if (backendResponse.ok) {
      return backendResponse.json();
    }
    throw new ApiError(backendResponse);
  };

  static getSubmission = async (submissionId: string): Promise<Submission> => {
    const backendResponse = await commonFetch(
      `${process.env.REACT_APP_BACKEND_URL}/submissions/${submissionId}`,
      { method: 'GET' },
    );
    if (backendResponse.ok) {
      return backendResponse.json();
    }
    throw new ApiError(backendResponse);
  };

  static deleteDraft = async (submissionId: string) => {
    const backendResponse = await commonFetch(
      `${process.env.REACT_APP_BACKEND_URL}/submissions/${submissionId}`,
      { method: 'DELETE' },
    );
    if (backendResponse.ok) {
      return backendResponse.json();
    }

    throw new ApiError(backendResponse);
  };
}

export default SubmissionsApi;
