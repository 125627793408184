import { AuthContextState } from './authReducer';

export const userProfileSelector = (state: AuthContextState) => state.userProfile;
export const isPortalPaidSelector = (
  state: AuthContextState,
) => state.userProfile?.organization.billing?.is_portal_paid;
export const userRolePermissionsSelector = (state: AuthContextState) => state.userRolePermissions;
export const isAuthenticatedSelector = (state: AuthContextState) => state.isAuthenticated;
export const isAuthenticatingSelector = (state: AuthContextState) => state.isAuthenticating;
export const forgotPasswordEmailSelector = (state: AuthContextState) => state.forgotPasswordEmail;
export const sessionUserSelector = (state: AuthContextState) => state.sessionUser;
export const currentUserSelector = (state: AuthContextState) => state.currentUser;
