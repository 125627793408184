export const publicRoutes = {
  signIn: '/sign-in',
  signUp: '/sign-up',
  verifyIntention: '/sign-up/verify/:verificationId',
  forgotPassword: '/forgot-password',
  updatePassword: 'auth/password-update/change-password/:token',
  acceptInvitation: 'auth/invite/:invitationId',
  registrationCompleted: '/registration-completed',
  passwordUpdated: '/password-updated',
  emailSent: '/email-sent',
  confirmEmailSent: '/confirm-email-sent',
};

export const privateRoutes = {
  home: '/',

  signUpOnboardFacility: '/sign-up/onboard-facility',
  signUpScheduleCall: '/sign-up/schedule-call',

  claims: '/claims',
  claimSubmissions: '/claims/submissions',
  claimEClaimLink: '/claims/eclaimlink',
  claimRiayati: '/claims/riayati',
  claimShafafiya: '/claims/shafafiya',
  claimDetails: '/claims/details/:claimId',
  claimSubmissionForm: '/claims/submissions/form/:submissionId',
  claimCreateSubmissionForm: '/claims/submissions/form',
  claimCreateDraftResubmissionForm: '/claims/submissions/resubmission/:claimId',

  dashboard: '/dashboard',
  dashboardOverview: '/dashboard/overview',
  dashboardClinicians: '/dashboard/clinicians',
  dashboardCollections: '/dashboard/collections',
  dashboardAgeing: '/dashboard/ageing',
  dashboardPayments: '/dashboard/payments',
  dashboardRejections: '/dashboard/rejections',

  branchForm: '/settings/branch-management/edit/:branchId',
  branchAddForm: '/settings/branch-management/add',
  branchList: '/settings/branch-management',

  userForm: '/settings/user-management/edit/:userId',
  userAddForm: '/settings/user-management/add',
  userList: '/settings/user-management',

  accountSettings: '/settings/account',
  accountName: '/settings/account/name',
  accountPhone: '/settings/account/phone',
  accountEmail: '/settings/account/email',
  accountPassword: '/settings/account/password',

  report: '/report',
};
