import { asClass, asValue, createContainer } from 'awilix';

import LocalStorageService from 'services/LocalStorageService';
import PlausibleWebAnalyticsService, { WebAnalyticsConfig } from 'services/WebAnalyticsService';
import ComponentNotificationService from '../services/ComponentNotificationService';
import Service from './service-keys';

// Creating IoC container

const container = createContainer();

container.register(Service.componentNotification, asClass(ComponentNotificationService).singleton());
container.register(Service.localStorage, asClass(LocalStorageService).singleton());

container.register({
  [Service.webAnalyticsConfig]: asValue({
    apiUrl: process.env.REACT_APP_WEB_ANALYTICS_API_URL,
    domain: process.env.REACT_APP_WEB_ANALYTICS_DOMAIN,
    trackLocalhost: process.env.REACT_APP_WEB_ANALYTICS_DEBUG === 'true',
  } as WebAnalyticsConfig),
});
container.register(Service.webAnalytics, asClass(PlausibleWebAnalyticsService).singleton());

// resolve LocalstorageService immediately to listen events
container.resolve(Service.localStorage);
container.resolve(Service.webAnalytics);

export default container;
