/* eslint-disable react/display-name */
import React, { useMemo } from 'react';
import { Spin } from 'custom-test-antd';
import { BrowserRouter, Routes } from 'react-router-dom';
import { ThemeProvider } from 'react-jss';
import { klaimTheme } from 'components/application/App';
import { UserOnboardingWrapper } from 'components/user-onboarding';
import { AuthenticatorContainer } from './authenticator';
import { LoadingLayout } from './layouts/loading-layout';
import { AuthProvider } from '../authentication/context/authContext';
import PublicRoutes from './routes/PublicRoutes';
import PrivateRoutes from './routes/PrivateRoutes';

import './App.pcss';

const App = () => (
  <AuthProvider>
    <div>
      <ThemeProvider theme={klaimTheme}>
        <BrowserRouter>
          <AuthenticatorContainer
            LoadingComponent={useMemo(() => (() => (
              <div className="Application-authSection">
                <LoadingLayout>
                  <Spin spinning tip="Authenticating..." />
                </LoadingLayout>
              </div>
            )), [])}
            AuthenticatedComponent={useMemo(() => (() => (
              <UserOnboardingWrapper branchRequired callRequired>
                {PrivateRoutes({})}
              </UserOnboardingWrapper>
            )), [])}
            UnAuthenticatedComponent={useMemo(() => (() => (
              <Routes>
                {PublicRoutes({})}
              </Routes>
            )), [])}
          />
        </BrowserRouter>
      </ThemeProvider>
    </div>
  </AuthProvider>
);

export default App;
