import { AllUserResult, ChangePasswordAttribute, UserProfile } from './userProfileTypes';
import { ApiError, commonFetch, encodeQueryFilter } from '../apiHelper';

export class UserProfileApi {
  static changePassword = async (data: ChangePasswordAttribute) => {
    const requestURL = `${process.env.REACT_APP_BACKEND_URL}/users/update-password`;
    const backendResponse = await commonFetch(requestURL, {
      method: 'PUT',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (backendResponse.ok) {
      return;
    }
    throw new ApiError(backendResponse);
  };

  static updateProfile = async (data: Partial<UserProfile>) => {
    const requestURL = `${process.env.REACT_APP_BACKEND_URL}/users/profile`;
    const backendResponse = await commonFetch(requestURL, {
      method: 'PUT',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (backendResponse.ok) {
      return backendResponse.json();
    }
    throw new ApiError(backendResponse);
  };

  static getProfile = async () => {
    const requestURL = `${process.env.REACT_APP_BACKEND_URL}/users/profile`;
    const backendResponse = await commonFetch(requestURL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (backendResponse.ok) {
      return backendResponse.json();
    }
    throw new ApiError(backendResponse);
  };

  static getAllUsers = async (branchId?: number): Promise<AllUserResult> => {
    const filter: Record<string, any> = {};
    if (branchId) {
      filter.branchId = branchId;
    }
    const requestURL = `${process.env.REACT_APP_BACKEND_URL}/v3/users/all${encodeQueryFilter(filter)}`;
    const backendResponse = await commonFetch(requestURL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (backendResponse.ok) {
      return backendResponse.json();
    }
    throw new ApiError(backendResponse);
  };
}

export default UserProfileApi;
