import Service from 'ioc-container/service-keys';
import { IoCcontainer } from 'ioc-container/container-type';
import { AuthEventType, UserChangeEventType } from './ComponentNotificationService';

export interface ILocalStorageService {
  setItem: (key: LocalStorageKey, value: string) => void;
  getItem: (key: LocalStorageKey) => string | null;
  removeItem: (key: LocalStorageKey) => void;
}

export enum LocalStorageKey {
  DashboardFilter = 'dashboardFilter',
  ClaimListFilter = 'claimListFilter',
  ClaimListPagination = 'claimListPagination',
  SubmissionListFilter = 'submissionListFilter',
  SubmissionListPagination = 'submissionListPagination',
}

export class LocalStorageService implements ILocalStorageService {
  constructor(container: IoCcontainer) {
    this.clearLocalStorage = this.clearLocalStorage.bind(this);

    const notifyService = container[Service.componentNotification];

    notifyService.on(AuthEventType.UserLogin, this.clearLocalStorage);
    notifyService.on(UserChangeEventType.CurrentUserUpdated, this.clearLocalStorage);
  }

  // eslint-disable-next-line class-methods-use-this
  clearLocalStorage() {
    localStorage.removeItem(LocalStorageKey.DashboardFilter);
    localStorage.removeItem(LocalStorageKey.ClaimListFilter);
    localStorage.removeItem(LocalStorageKey.ClaimListPagination);
    localStorage.removeItem(LocalStorageKey.SubmissionListFilter);
    localStorage.removeItem(LocalStorageKey.SubmissionListPagination);
  }

  // eslint-disable-next-line class-methods-use-this
  setItem(key: LocalStorageKey, value: string) {
    localStorage.setItem(key, value);
  }

  // eslint-disable-next-line class-methods-use-this
  getItem(key: LocalStorageKey) {
    return localStorage.getItem(key);
  }

  // eslint-disable-next-line class-methods-use-this
  removeItem(key: LocalStorageKey) {
    localStorage.removeItem(key);
  }
}

export default LocalStorageService;
