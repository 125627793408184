import { KlaimTheme } from '../../interfaces/klaim-theme.interface';

export const klaimTheme: KlaimTheme = {
  screen: {
    mobile: 640,
    tablet: 768,
    desktop: 1024,
  },
  font: {
    weightSemibold: '600',
  },
  container: {
    color: {
      primary: 'white',
      secondary: '#F5F7FB',
      tertiary: 'rgba(231, 236, 245, .6)',
    },
    background: {
      primary: '#FFFFFF',
      secondary: '#F5F7FB',
    },
    border: {
      primary: '#EEEAFB',
      secondary: '#E7ECF5',
    },
  },
  formButton: {
    color: {
      primary: 'white',
      secondary: '#F5F7FB',
      tertiary: '#6135FB',
    },
    background: {
      primary: 'white',
      secondary: '#6135FB',
    },
    border: {
      primary: '#2983FD',
      secondary: '#6135FB',
    },
  },
  formElement: {
    color: {
      primary: '#302E38',
      secondary: '#6135FB',
      tertiary: '#222B45',
      error: '#D90B0B',
      success: '#27C6A2',
      validated: '#41D8B6',
    },
    border: {
      primary: '#E7ECF5',
      secondary: '#6135FB',
      error: '#D90B0B',
      validated: '#41D8B6',
    },
    background: {
      primary: 'white',
      secondary: '#F5F7FB',
    },
  },
};
