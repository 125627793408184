import React from 'react';
import { FormikHelpers, Formik } from 'formik';
import { Form, Button } from 'custom-test-antd';
import { QuestionCircleFilled } from '@ant-design/icons';

import { InputPasswordWrapper } from '../../../../utils/form-helpers/antd-formik';
import { AccountLayoutCard } from '../account-layout-card';
import { PasswordGuideTooltip } from '../../../authentication/password-guide-tooltip';

import './AccountPassword.pcss';

interface Props {
  onCancel: () => void,
  onSubmit: (values: any, formikHelpers: FormikHelpers<any>) => any,
  initialValues: any
}

export const AccountPassword: React.FC<Props> = ({ onSubmit, initialValues, onCancel }) => (
  <AccountLayoutCard
    className="AccountPassword"
    title={(
      <AccountLayoutCard.Title
        title="Change Password"
        description="Changing your password will sign you out of your devices, with some exceptions."
      />
    )}
  >
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      {({ handleSubmit, isSubmitting }) => (
        <Form layout="vertical">
          <InputPasswordWrapper
            size="large"
            antFieldProps={{ label: 'Old Password' }}
            gray
            name="oldPassword"
            placeholder="Old Password"
          />
          <InputPasswordWrapper
            size="large"
            antFieldProps={{ label: 'New Password' }}
            gray
            name="newPassword"
            placeholder="New Password"
          />
          <div className="AccountPassword-guideRow">
            <InputPasswordWrapper
              size="large"
              antFieldProps={{ label: 'Confirm New Password', className: 'AccountPassword-repeatPassword' }}
              gray
              name="newPasswordRepeat"
              placeholder="Confirm New Password"
            />
            <Form.Item>
              <PasswordGuideTooltip>
                <QuestionCircleFilled className="AccountPassword-questionIcon" />
              </PasswordGuideTooltip>
            </Form.Item>
          </div>
          <div className="AccountPassword-buttons">
            <Button
              size="large"
              type="link"
              onClick={onCancel}
            >
              Cancel
            </Button>
            <Button
              htmlType="submit"
              type="primary"
              size="large"
              onClick={() => handleSubmit()}
              loading={isSubmitting}
            >
              Save
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  </AccountLayoutCard>
);

export default AccountPassword;
