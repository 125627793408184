import React from 'react';
import { createRoot } from 'react-dom/client';
import moment from 'moment-timezone';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

// register container and resolve necessary services
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { container as _iocContainer } from 'ioc-container';

import 'antd/dist/antd.less';
import App from 'components/application';
import './index.pcss';

moment.tz.setDefault('Asia/Dubai');
dayjs.extend(customParseFormat);

const container = document.getElementById('root');
if (container) {
  const root = createRoot(container);
  root.render(
    <App />,
  );
}
