import React, { useState, useMemo } from 'react';
import { useMount } from 'react-use';
import { UserProfileApi, AuthenticationApi, BranchApi } from 'api';
import * as Yup from 'yup';
import { LoginAsUser } from './LoginAsUser';
import { submitValidate } from '../../../../utils/form-helpers/antd-formik';
import { AllUserResult } from '../../../../api/user-profile/userProfileTypes';

const schema = Yup.object({
  user: Yup.array().required('Required'),
});

export const LoginAsUserContainer = () => {
  const [allUsers, setAllUsers] = useState<AllUserResult>();
  const [branches, setBranches] = useState<Awaited<ReturnType<typeof BranchApi.getAllBranch>>>([]);

  useMount(async () => {
    const fetchedResult = await UserProfileApi.getAllUsers();
    setAllUsers(fetchedResult);
    const allBranches = await BranchApi.getAllBranch();
    setBranches(allBranches);
  });

  const organizations = useMemo(() => (
    allUsers?.organizations.map((organization) => ({
      value: organization.id,
      label: organization.name ?? '',
      children: organization.roles.map((role) => ({
        value: role.id,
        label: role.name,
        children: role.users.map((user) => ({
          value: user.id,
          label: `${user.name ?? ''} ${user.email}`,
        })),
      })),
    }))
  ), [allUsers]);

  return (
    // eslint-disable-next-line react/react-in-jsx-scope
    <LoginAsUser
      options={organizations}
      branches={branches}
      onBranchChange={async (branchId) => {
        const fetchedResult = await UserProfileApi.getAllUsers(branchId);
        setAllUsers(fetchedResult);
      }}
      onSubmit={async (values, formikHelpers) => {
        if (!submitValidate(values, schema, formikHelpers)) {
          return;
        }
        const selectedUserId = values.user?.[2] as number;
        await AuthenticationApi.loginAsUser(selectedUserId);
        window.location.reload();
      }}
    />
  );
};

export default LoginAsUserContainer;
