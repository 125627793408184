export enum ClaimsPortal {
  eClaimLink = 'eclaimlink',
  Shafafiya = 'shafafiya',
  Riayati = 'riayati',
}

export interface ListFilter {
  search?: string,
  status?: string,
  from?: string,
  to?: string,
  providerId?: string,
  receiver?: string,
  payer?: string,
  hasPendingSubmission?: string,
  resubmitted?: string,
  sorting?: string,
}

export interface ClaimListDetails {
  id: string;
  claim_id: string;
  status: ClaimStatus;
  submission_error: boolean;
  receiver_id: string;
  payer_id: string;
  first_submission_date: string;
  pending_submission: boolean;
  claim: {
    gross_amount: number;
    patient_share: number;
    net_amount: number;
    resubmission_count: number;
  };
  remittance: any;
  member_id: string;
  payer_name: string;
  receiver_name: string;
}

export enum ClaimStatus {
  submitting = 'submitting',
  submitted = 'submitted',
  paid = 'paid',
  partially_paid = 'partially_paid',
  rejected = 'rejected',
  draft = 'draft',
}

export type ClaimSubmissionError = 'invalid_credentials' | 'unexpected_error' | 'dhpo_error';

export interface Submission {
  id: string;
  file_id: string;
  status: 'submitting' | 'submitted' | 'error';
  submission_error?: {
    code: ClaimSubmissionError;
    details: string;
  };
  submission_date: string;
  id_payer?: string;
  gross_amount: number;
  patient_share: number;
  net_amount: number;
  activity_ids: Array<string>;
  resubmission_type?: string;
  resubmission_comment?: string;
  resubmission_attachment_id?: string;
}

export interface PatientEncounter {
  facility_id: string;
  patient_id: string;
  type: number;
  start: string;
  end: string;
  start_type: number;
  end_type: number;
}

export interface Diagnosis {
  type: 'Principal' | 'Secondary';
  code: string;
  short_description: string;
  long_description: string;

  dx_info?: {
    code: string;
  };
}

export interface Remittance {
  payment_amount: number;
  denied_amount: number;
  latest_payment_reference: string;
  latest_settlement_date: string;
  payment_ref: string;
  settlement_date: string;
  denial?: {
    code: string;
    description?: string;
  };
}

export interface Activity {
  id: string;
  status?: ClaimStatus;
  activity_ids: Array<string>;
  active_activity_id: string;
  first_submission_date: string;
  start: string;
  activity_performed: {
    type: number;
    code: string;
    short_description: string;
    long_description: string;
    full_description: string;
  };
  quantity: number;
  gross: number;
  patient_share: number;
  net: number;
  clinician: {
    id: string;
    name: string;
  };
  prior_authorization_provider_id: string;
  remittance_advices: Array<any>;
  observations: [
    {
      type: string;
      code: string;
      value: string;
      value_type: string;
    },
  ];
  payment_amount: number;
  denied_amount?: number;
  history: Array<any>;
}

export interface ClaimDetailsInterface {
  patient: any; // TODO: clarify with backend
  contract: any; // TODO: clarify with backend
  id: string;
  claim_id: string;
  status: ClaimStatus;
  submission_error: boolean;
  receiver_id: string;
  emirates_id?: string;
  receiver_name: string;
  id_payer?: string;
  payer_id: string;
  payer_name: string;
  first_submission_date: string;
  pending_submission: boolean;
  member_id: string;
  file_ids: Array<any>;
  sender_id: string;
  submissions: Array<Submission>;
  activities: Array<Activity>;
  encounter: PatientEncounter;
  diagnoses: Array<Partial<Diagnosis>>;
  claim: {
    gross_amount: number;
    patient_share: number;
    net_amount: number;
    resubmission_count: number;
  };
  remittance: Remittance;
  history?: Array<any>;
}

export interface ClaimList {
  count?: number;
  data?: Array<ClaimListDetails>;
}
