import EventEmitter from 'eventemitter3';

export enum AuthEventType {
  UnauthorizedRequest = 'UNAUTHORIZED_REQUEST',
  UserLogin = 'USER_LOGIN',
  UserAuthenticated = 'USER_AUTHENTICATED',
  UserLogout = 'USER_LOGOUT',
}

export enum UserChangeEventType {
  CurrentUserUpdated = 'CURRENT_USER_UPDATED',
}

export class ComponentNotificationService extends EventEmitter {

}

export default ComponentNotificationService;
