import Plausible from 'plausible-tracker';
import Service from 'ioc-container/service-keys';
import { IoCcontainer } from 'ioc-container/container-type';

type TrackEvent = (event: string, props?: any) => void;

export type WebAnalyticsConfig = {
  apiUrl: string;
  domain: string;
  trackLocalhost: boolean;
};

export interface WebAnalyticsService {
  trackEvent: (event: string, props?: any) => void;
}

class PlausibleWebAnalyticsService implements WebAnalyticsService {
  private trackEventFn: TrackEvent;

  constructor(container: IoCcontainer) {
    const config = container[Service.webAnalyticsConfig];
    const { apiUrl, domain, trackLocalhost } = config;
    if (!apiUrl || !domain) {
      throw new Error('apiUrl and domain should be defined');
    }

    const { trackEvent, enableAutoPageviews } = Plausible({
      apiHost: apiUrl,
      domain,
      trackLocalhost,
    });

    enableAutoPageviews();

    this.trackEventFn = trackEvent;
  }

  trackEvent(event: string, props?: any) {
    this.trackEventFn(event, props);
  }
}

export default PlausibleWebAnalyticsService;
