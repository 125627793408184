import { commonFetch } from 'api';
import { RbacItemsService, RbacRole } from 'services/rbac-manager/interfaces';

export class RbacItemsHttpAdapter implements RbacItemsService {
  cache: Map<number, RbacRole> = new Map<number, RbacRole>();

  async loadCache() {
    const backendResponse = await commonFetch(`${process.env.REACT_APP_BACKEND_URL}/rbac/roles/current`, {
      method: 'GET',
    });
    if (backendResponse.ok) {
      const role = await backendResponse.json();
      this.cache.set(role.id, role);

      return role;
    }

    throw backendResponse;
  }

  getRole(roleId: number) {
    const role = this.cache.get(roleId);
    if (!role) {
      throw new Error('Role does not exist');
    }

    return role;
  }

  // eslint-disable-next-line class-methods-use-this
  async getRoles() {
    const backendResponse = await commonFetch(`${process.env.REACT_APP_BACKEND_URL}/rbac/roles/all`, {
      method: 'GET',
    });
    if (backendResponse.ok) {
      return backendResponse.json();
    }

    throw backendResponse;
  }

  // eslint-disable-next-line class-methods-use-this
  async getPermissions() {
    const backendResponse = await commonFetch(`${process.env.REACT_APP_BACKEND_URL}/rbac/permissions/all`, {
      method: 'GET',
    });
    if (backendResponse.ok) {
      return backendResponse.json();
    }

    throw backendResponse;
  }
}
