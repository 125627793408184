import { Dispatch } from 'react';

import { AuthenticationApi, UserProfileApi } from 'api';
import { notification } from 'custom-test-antd';
import { rbacManager } from 'services/rbac-manager/RbacManager';
import { container } from 'ioc-container';
import Service from 'ioc-container/service-keys';
import {
  AuthContextState,
  setAuthenticated,
  setAuthenticating,
  setCurrentUser,
  setForgotPasswordEmail,
  setSessionUser,
  storeUserPermissions,
  storeUserProfile,
} from './authReducer';
import {
  currentUserSelector,
  isAuthenticatedSelector,
  sessionUserSelector,
  userProfileSelector,
} from './authSelectors';
import { CreateRegistrationIntentionData, IntentionStatus } from '../../../api/authentication/authenticationTypes';
import { AuthEventType } from '../../../services/ComponentNotificationService';

export const checkUser = async (dispatch: Dispatch<any>, getState: () => AuthContextState) => {
  const state = getState();
  const sessionUser = sessionUserSelector(state);
  const currentUser = currentUserSelector(state);

  const result = await AuthenticationApi.me(false);
  if (result.sessionUser.id !== sessionUser?.id || result.currentUser.id !== currentUser?.id) {
    window.location.href = '/';
  }
};

export const getUserPermissions = async (dispatch: Dispatch<any>, getState: () => AuthContextState) => {
  const state = getState();
  const userProfile = userProfileSelector(state);
  if (!userProfile) return;

  await rbacManager.loadCache();
  const rolePermissions = rbacManager.getRolePermissions(userProfile.rbac_role_id);

  dispatch(storeUserPermissions(rolePermissions));
};

export const fetchUserProfile = async (dispatch: Dispatch<any>) => {
  const userProfile = await UserProfileApi.getProfile();
  dispatch(storeUserProfile(userProfile));
};

const finishAuthentication = async (dispatch: Dispatch<any>) => {
  const result = await AuthenticationApi.me();
  dispatch(setSessionUser(result.sessionUser));
  dispatch(setCurrentUser(result.currentUser));
  await dispatch(fetchUserProfile);
  await dispatch(getUserPermissions);
  dispatch(setAuthenticated(true));
  container.resolve(Service.componentNotification).emit(AuthEventType.UserAuthenticated);
};

export const signIn = (email: string, password: string, rememberMe: boolean) => async (dispatch: Dispatch<any>) => {
  const result = await AuthenticationApi.signIn(email, password, rememberMe);
  if (process.env.REACT_APP_USE_AUTH_TOKEN === 'true') {
    localStorage.setItem('accessToken', result?.authToken?.token);
  }
  await finishAuthentication(dispatch);
  container.resolve(Service.componentNotification).emit(AuthEventType.UserLogin);
  return result;
};

export const signOut = async (dispatch: Dispatch<any>, getState: () => AuthContextState) => {
  container.resolve(Service.componentNotification).emit(AuthEventType.UserLogout);
  const state = getState();
  const sessionUser = sessionUserSelector(state);
  const userProfile = userProfileSelector(state);
  if (sessionUser?.id && sessionUser?.id !== userProfile?.id) {
    await AuthenticationApi.loginAsUser(null);
    window.location.reload();
    return;
  }
  await AuthenticationApi.signOut();
  dispatch(setAuthenticated(false));
  if (process.env.REACT_APP_USE_AUTH_TOKEN === 'true') {
    localStorage.removeItem('accessToken');
  }
};

export const authenticate = async (dispatch: Dispatch<any>) => {
  try {
    dispatch(setAuthenticating(true));
    await finishAuthentication(dispatch);
  } catch (error) {
    dispatch(setAuthenticated(false));
  } finally {
    dispatch(setAuthenticating(false));
  }
};

export const forgotPassword = (email: string) => async (dispatch: Dispatch<any>) => {
  const result = await AuthenticationApi.forgotPassword(email);
  if (result.success) {
    dispatch(setForgotPasswordEmail(email));
  } else {
    dispatch(setForgotPasswordEmail());
  }
  return result;
};

export const changePassword = (password: string, token: string) => async () => {
  const result = await AuthenticationApi.changePassword(password, token);
  return result;
};

export const processUnauthorizedRequest = (dispatch: Dispatch<any>, getState: () => AuthContextState) => {
  const state = getState();
  const isAuthenticated = isAuthenticatedSelector(state);
  if (!isAuthenticated) {
    return;
  }
  dispatch(setAuthenticated(false));
  notification.error({
    message: 'Unauthorized',
    description: 'Your access token has expired. Please login again.',
  });
};

export const acceptInvitation = (invitationId: string, values: CreateRegistrationIntentionData) => async () => {
  const result = await AuthenticationApi.acceptInvitation(invitationId, values);
  if (result.status === IntentionStatus.Completed && process.env.REACT_APP_USE_AUTH_TOKEN === 'true') {
    localStorage.setItem('accessToken', result?.authToken?.token as string);
  }
};

export const createRegistrationIntention = (values: CreateRegistrationIntentionData) => async () => (
  AuthenticationApi.createRegistrationIntention(values)
);

export const checkRegistrationIntention = (intentionId: string) => async () => {
  const result = await AuthenticationApi.checkRegistrationIntention(intentionId);
  if (result.status === IntentionStatus.Completed && process.env.REACT_APP_USE_AUTH_TOKEN === 'true') {
    localStorage.setItem('accessToken', result?.authToken?.token as string);
  }
  return result;
};

export const resendRegistrationIntention = (intentionId: string) => async () => (
  AuthenticationApi.resendRegistrationIntention(intentionId)
);

export const executeRegistrationIntention = (verificationId: string) => async () => (
  AuthenticationApi.executeRegistrationIntention(verificationId)
);
