import { ApiError, commonFetch, encodeQueryFilter } from '../apiHelper';
import { ClaimDetailsInterface, ClaimList, ClaimsPortal, ListFilter } from './claimTypes';
import { RawClaim } from './rawClaimType';

import { ResubmissionType, Submission } from '../submissions/submissionsTypes';

export class ClaimsApi {
  static getClaims = async (
    portal: ClaimsPortal,
    filter: ListFilter,
    pagination: { pageSize: number; current: number },
  ): Promise<ClaimList> => {
    const queryParams: any = {
      limit: pagination.pageSize,
      skip: (pagination.current - 1) * pagination.pageSize,
      from: filter.from,
      to: filter.to,
    };

    if (filter.hasPendingSubmission) {
      queryParams.hasPendingSubmission = filter.hasPendingSubmission;
    }
    if (filter.providerId) {
      queryParams.providerId = filter.providerId;
    }
    if (filter.search) {
      queryParams.search = filter.search;
    }
    if (filter.status) {
      queryParams.status = filter.status;
    }
    if (filter.receiver) {
      queryParams.receiver = filter.receiver;
    }

    if (filter.payer) {
      queryParams.payer = filter.payer;
    }

    if (filter.resubmitted) {
      queryParams.resubmitted = filter.resubmitted;
    }

    if (filter.sorting) {
      queryParams.sortBy = filter.sorting;
      queryParams.sortOrder = 'desc';
    }

    const requestURL = `${process.env.REACT_APP_BACKEND_URL}/claims/portal/${portal}${encodeQueryFilter(queryParams)}`;
    const backendResponse = await commonFetch(requestURL, {
      method: 'GET',
    });
    if (backendResponse.ok) {
      return backendResponse.json();
    }
    throw new ApiError(backendResponse);
  };

  static getClaim = async (claimId: string, portal: ClaimsPortal): Promise<ClaimDetailsInterface> => {
    const backendResponse = await commonFetch(
      `${process.env.REACT_APP_BACKEND_URL}/claims/${claimId}?portal=${portal.toUpperCase()}`,
      { method: 'GET' },
    );
    if (backendResponse.ok) {
      return backendResponse.json();
    }
    throw new ApiError(backendResponse);
  };

  static getRawClaim = async (claimId: string, portal: ClaimsPortal, senderId: string): Promise<RawClaim> => {
    const filter = encodeQueryFilter({
      claimId,
      portal: portal.toUpperCase(),
      senderId,
    });
    const backendResponse = await commonFetch(
      `${process.env.REACT_APP_BACKEND_URL}/claims/raw${filter}`,
      { method: 'GET' },
    );
    if (backendResponse.ok) {
      return backendResponse.json();
    }
    throw new ApiError(backendResponse);
  };

  static prepareResubmission = async (
    claimId: string,
    portal: ClaimsPortal,
    senderId: string,
    resubmissionType: ResubmissionType,
  ): Promise<Submission> => {
    const filter = encodeQueryFilter({
      claimId,
      portal: portal.toUpperCase(),
      senderId,
      resubmissionType,
    });
    const backendResponse = await commonFetch(
      `${process.env.REACT_APP_BACKEND_URL}/claims/prepare-resubmission${filter}`,
      { method: 'GET' },
    );
    if (backendResponse.ok) {
      return backendResponse.json();
    }
    throw new ApiError(backendResponse);
  };
}

export default ClaimsApi;
